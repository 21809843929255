export default {
    permission: {
        admin: 'Administrateur',
        config_show: 'Afficher la configuration',
        config_update: 'Mettre à jour la configuration',
        user_index: 'Lister les utilisateurs',
        user_show: 'Afficher un utilisateur',
        user_store: 'Créer un utilisateur',
        user_update: 'Mettre à jour un utilisateur',
        user_destroy: 'Supprimer un utilisateur',
        user_restore: 'Restaurer un utilisateur',
        role_index: 'Lister les rôles',
        commune_index: 'Lister les communes',
        commune_show: 'Afficher une commune',
        commune_store: 'Créer une commune',
        commune_update: 'Mettre à jour une commune',
        commune_destroy: 'Supprimer une commune',
    },
}
